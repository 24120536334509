import { getEnv } from 'common-nextjs';
import { Uuid } from 'common-types';
import { qsStringify, sortObjectKeys } from 'utils';
import { fetchFacetItemsNoMeta } from '~/api/items';

const SHAPED_KEY = 'fYNyb2YfZJ3qSNWGtmWwj1eO26cupKrA8qDhTuVC';

interface ShapedFetcherOptions {
  method?: string;
  body?: any;
  headers?: HeadersInit;
  params?: Record<string, string>;
  silent?: string;
}

type ShapedErrorResponse =
  | {
      detail: { msg: string; type: string }[];
    }
  | { message: string };

async function shapedFetcher<T>(
  url: string,
  options: ShapedFetcherOptions = {},
): Promise<
  | {
      data?: never;
      errors?: ShapedErrorResponse;
      headers: Headers;
      status: number;
    }
  | {
      data?: T;
      errors?: never;
      headers: Headers;
      status: number;
    }
> {
  const startTime = performance.now();
  const headers = new Headers(options.headers);

  headers.set('content-type', 'application/json');
  headers.set('x-api-key', SHAPED_KEY);

  const finalUrl = `https://api.shaped.ai${url}${qsStringify(options?.params)}`;
  const response = await fetch(finalUrl, {
    headers,
    method: options.method || 'GET',
    body: options.body ? JSON.stringify(options.body) : undefined,
  });

  const responseText = await response.text();
  if (getEnv() === 'development' || process.env.NODE_ENV === 'development') {
    let data: any;
    try {
      data = JSON.parse(responseText);
    } catch (e) {
      data = '** Response is not JSON **';
    }
    let log: string = `[shapedFetcher] ${(
      options.method || 'get'
    ).toUpperCase()} [${
      response.status
    }] ${finalUrl} [x-request-id: ${response.headers.get(
      'x-request-id',
    )}] ${Math.round(performance.now() - startTime)}ms\n`;

    if (options.silent) {
      log += 'Request or response body is not logged in silent mode';
    } else {
      if (options.body) {
        log += `Body: ${JSON.stringify(options.body)}\n`;
      }

      log += `Response: ${JSON.stringify(sortObjectKeys(data), null, 2)}`;
    }

    console.log(log);
  }

  if (response.ok) {
    try {
      return {
        data: JSON.parse(responseText) as T,
        headers: response.headers,
        status: response.status,
      };
    } catch (e) {
      console.log(`Failed to parse JSON: ${finalUrl} ${responseText}`);
      return {
        data: undefined,
        errors: {
          detail: [
            { msg: "Failed to parse Shaped.ai's response JSON", type: 'error' },
          ],
        },
        headers: response.headers,
        status: response.status,
      };
    }
  } else {
    let data = JSON.parse(responseText) as ShapedErrorResponse;

    // Their forbidden response is incorrectly formatted for the rest of their API
    if ('message' in data) {
      data = { detail: [{ msg: data.message, type: 'error' }] };
    }

    return {
      errors: data as ShapedErrorResponse,
      headers: response.headers,
      status: response.status,
    };
  }
}

export interface ShapedAiModel {
  model_name: string;
  model_uri: string;
  created_at: string;
  trained_at: string;
  status: string;
}

export async function fetchShapedModels() {
  const response = await shapedFetcher<{ models: ShapedAiModel[] }>(
    '/v1/models',
  );
  if (!response.data) {
    throw new Error('Failed to fetch models');
  }

  return response.data.models;
}

export async function fetchShapedRecommendedItemIds(
  modelName: string,
  userId: Uuid | number,
) {
  const response = await shapedFetcher<{
    ids: string[];
    scores: number[];
    meta?: any;
  }>(`/v1/models/${modelName}/rank`, {
    method: 'POST',
    body: {
      user_id: String(userId),
      flush_paginations: false,
      return_metadata: false,
      config: {
        limit: 36,
        diversity_factor: 0.5,
        diversity_window: 4,
        diversity_attributes: ['category_2'],
        retrieval_k: 1250,
        retriever_k_override: {
          knn: 1000,
          toplist: 0,
          trending: 0,
          random: 250,
          chronological: 0,
        },
      },
    },
  });

  if (response.data) {
    return {
      ...response.data,
      ids: response.data.ids?.slice(0, 18),
    };
  }
}

export async function fetchShapedRecommendedRailsItems(userId: Uuid | number) {
  const response = await fetchShapedRecommendedItemIds(
    'ss_recs_rework_prod_v2',
    userId,
  );
  if (!response?.ids) {
    return;
  }

  return await fetchFacetItemsNoMeta({
    include_ids: response.ids.join(','),
    include_meta: 0,
  });
}
